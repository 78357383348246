<template>
    <div v-if="challenges !== null">
      <div v-for="challenge in challenges" :key="challenge.id">
        <div class="challenge" @click="viewChallenge(challenge.id)">
          <b-card no-body>
            <b-card-header>{{challenge.away.name}} at {{challenge.home.name}}</b-card-header>
            <b-card-body>
            </b-card-body>
          </b-card>
          <hr />
        </div>
        <hr />
      </div>
    </div>
  </template>
  
  <script>
  import { api } from "@/utils/api";
  
  export default {
    name: "AdminChallenges",
    data() {
      return {
        challenges: null,
      };
    },
    created() {
      let self = this;
      api(
        `query{ playableChallenges{ id home { id logo name } away { id logo name } } }`
      ).then((data) => {
        self.challenges = data.playableChallenges;
      });
    },
    methods: {
      viewChallenge(cid) {
        this.$router.push({
          name: "ModifyChallenge",
          params: { id: cid },
        });
      },
    },
  };
  </script>
  
  <style scoped>
  .logo {
    margin: 20px;
  }
  .challenge {
    cursor: pointer;
    text-align: center;
  }
  </style>
  